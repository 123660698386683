.loading {
    width: 10em;
    height: 10em;
    margin: 0 auto;

    .text {
        text-align: center;
        margin-top: 1em;
    }

    .pacman {
        width: 10em;
        height: 10em;
        background: black;
        border-radius: 50%;

        .mouth {
            width: 100%;
            height: 100%;
            background: #eaeaea;
            clip-path: polygon(110% 75%, 55% 50%, 100% 25%);
            animation: 0.75s ease-in-out 0s infinite alternate both eat;
        }
    }

    @keyframes eat {
        0% {
            clip-path: polygon(110% 75%, 55% 50%, 100% 25%);
        }

        100% {
            clip-path: polygon(110% 50%, 55% 50%, 100% 50%);
        }
    }
}