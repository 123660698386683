@import "../../grid";

.products-grid {
  @include grid-container;

  &::after {
    content: '';
    display: block;
    flex: auto;
  }

  .product-thumbnail {
    @include cols(12);
    @include cols(6, $container-breakpoint-md);
    @include cols(4, $container-breakpoint-lg);
    //@include cols(3, $container-breakpoint-xl);
    margin: $spacer/2 auto;
    text-decoration: none;
    color: inherit;

    &:focus {
      outline: 3px solid black;
    }

    &:hover,
    &:focus {
      .image::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.63), transparent);
      }
    }

    .image {
      width: 100%;
      height: 20em;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .meta {
      text-align: center;
      padding-top: $spacer/2;

      .title {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1em;
      }
    }
  }
}