@import "../../grid";

.btn-transparent {
  text-transform: uppercase;
  color: inherit;
  text-decoration: inherit;
  font-weight: 800;
  border: 3px solid white;
  background: none;
  padding: $spacer/2;

  &:hover,
  &:focus {
    background: #fff;
    color: #000;
  }
}

.btn-white {
  @extend .btn-transparent;
  background: #fff;
  color: black;
  border-color: #fff;

  &:hover,
  &:focus {
    background: #000;
    color: #fff;
  }
}

.btn-default {
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  font-family: inherit;
  text-transform: uppercase;
  text-decoration: inherit;
  font-weight: 800;
  padding: $spacer/2;
  margin: $spacer 0;
  border: none;
  background: linear-gradient(to top left, #D30055, #550053);

  &:hover,
  &:focus {
    background: #fff;
    color: #000;
    border: 3px solid black;
  }

}