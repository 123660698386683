.image-wrapper {
  position: relative;
  width: 100%;
  &.full-height {
    height: 100%;
  }

  &.not-loaded {
    min-height: 15em;
    overflow: hidden;

    img {
      opacity: 0;
    }

    > .loading, > img {
      position: absolute;
    }

    > .loading {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}