@import "../grid";

.product-page {
  @include grid-container;
  @include pl-0($container-breakpoint-lg);
  margin-top: 3em;

  input,
  select {
    font-size: inherit;
    font-family: inherit;
  }

  .gallery,
  .product-info {
    @include cols(12);
    margin-bottom: 3em;
  }

  @media screen and (min-width: $container-breakpoint-lg) {
    .gallery {
      order: 1;
      @include pl-0();
      @include cols(6);
    }

    .product-info {
      @include cols(6);
      order: 2;
    }
  }

  .gallery {
    img {
      width: 100%;
      height: auto;
    }
  }

  .product-info {
    .product-title {
      margin-bottom: 0.5em;
    }

    .order-form {
      margin: 1.5em auto;

      .has-donation {
        margin-bottom: $spacer/2;
      }

      label {
        display: block;
        font-weight: bold;
      }

      #variation {
        padding: $spacer/4;
        min-width: 50%;
        margin-top: 0.5em;
        border-radius: 10px;
      }

      .donation-slider {
        margin: $spacer/2 auto;

        .min-donation {
          font-size: 0.8em;
        }

        .row {
          @include grid-container;

          #donation {
            @include cols(9);
            @include cols(3, $container-breakpoint-md);
            margin-top: 0.5em;
          }

          .charity-icon {
            @include cols(3);
            @include cols(1, $container-breakpoint-md);
            height: auto;
            padding: $spacer/2;
            filter: invert(1);
          }
        }
      }

      .total {
        font-size: 2em;
        margin-top: $spacer;
      }
    }

    .quantity {
      margin: $spacer/2 auto;

      label {
        display: inline-block;
        margin-right: $spacer/2;
      }

      #quantity {
        display: inline-block;
        padding: 3px;
        width: 4em;
      }
    }
  }

  .status {
    border-radius: 5px;

    &.error {
      background: #ffb5b5;
      border: 1px solid #ffb5b5;
      color: #6b0707;
      padding: $spacer/2;
      margin-top: 1em;
    }

    &.success {
      background: #ccffcc;
      border: 1px solid #076b36;
      color: #076b36;
      padding: $spacer/2;
      margin-top: 1em;
    }
  }

  .price {
    font-size: 2em;
    font-weight: bold;
    margin: $spacer auto;
  }

  .charity {
    display: block;
    width: 10em;
    margin: $spacer 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .button-area {
    margin: $spacer auto;
  }

  .title {
    margin-bottom: 0.5em;
    font-family: Open Sans, sans-serif;
    font-size: 1em;
    text-transform: uppercase;
  }


  .long-description {
    margin: 2*$spacer auto;

    p {
      margin-bottom: 1.5em;
      line-height: 1.5;
    }
  }

  .collaborators {
    img {
      display: inline-block;
      width: 10em;
      height: auto;
      margin: 1em $spacer/4;
      mix-blend-mode: multiply;
      vertical-align: middle;
    }
  }
}