@import "../../grid";

header {
  @include container;
  @include grid-container;
  justify-content: space-between;
  margin: $spacer/2 auto;

  @media screen and (min-width: $container-breakpoint-md) {
    margin-top: 0;
  }

  .navbar-btn-container {
    @include cols(12);
    @include cols(5, $container-breakpoint-md);
    @include pl-0($container-breakpoint-md);
    @include center-children-vertically;

    text-align: center;
    font-size: 1.2em;

    @media screen and (min-width: $container-breakpoint-md) {
      text-align: left;
      font-size: 1em;
    }

    .menu-toggle {
      text-align: center;
      border: none;
      font-size: inherit;
      background-color: transparent;
      cursor: pointer;

      @media screen and (min-width: $container-breakpoint-md) {
        text-align: left;
      }

      svg {
        margin-right: $spacer/4;
      }

      &:focus {
        background-color: black;
        color: #ffffff;
        padding: $spacer/2;
      }
    }
  }

  .logo {
    @include cols(6);
    @include cols(2, $container-breakpoint-md);
    margin: 1rem auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  nav {
    text-align: center;
    font-size: 1.2em;
    @include cols(12);
    @include cols(5, $container-breakpoint-md);
    @include pr-0($container-breakpoint-md);

    @media screen and (min-width: $container-breakpoint-md) {
      text-align: right;
      font-size: 1em;
    }

    ul {
      list-style: none;

      @media screen and (min-width: $container-breakpoint-md) {
        height: 100%;
      }

      li {
        height: 100%;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 0 $spacer/2;

        svg {
          padding-right: $spacer/4;
        }

        &:last-child {
          @include pr-0();
        }

        a {
          text-decoration: none;
          color: inherit;
          transition: background-color 1s ease 0s, transform .5s cubic-bezier(1, -0.64, 0.29, 2.89) 0s;
          padding: 5px;

          &:hover {
            text-decoration: underline;
          }

          &:focus {
            font-weight: bold;
            color: #fff;
            background-color: #000;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.etsy {
  width: 1.2em;
  height: auto;
  // vertical-align: middle;
}

a:hover .etsy path,
a:focus .etsy path {
  fill: white;
}