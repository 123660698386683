@import "../grid";

.base-page {
    @include container();
    min-height: 50vh;

    h1 {
        text-transform: uppercase;
        margin-bottom: 1em;
    }

    h2 {
        font-size: 1.5em;
        margin: 1em 0 .5em;
    }

    p {
        margin: 0 auto 1.5em;
    }
}