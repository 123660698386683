@import "../../grid";

#footer {
    margin-top: $spacer * 4;
    background-color: #000;
    color: #fff;
    padding: $spacer*2 0;

    a {
        color: #fff;
        text-decoration: none;

        &:focus {
            background: white;
            color: black;
        }
    }

    .container {
        @include grid-container;
    }

    .col {
        @include cols(12);
        @include cols(4, $container-breakpoint-md);

        p {
            margin: $spacer/2 auto;
        }
    }

    nav ul {
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            margin: $spacer/2 0;
            list-style-type: none;

            &::before {
                content: '- ';
            }
        }
    }

    .su-logo {
        width: 10em;
        height: auto;
    }

    .copyright {
        @include container;
        text-align: center;
        margin: $spacer/2 auto 0;

        img {
            width: 5em;
            height: auto;
            margin-bottom: $spacer/2;
        }
    }
}