* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  background-color: #eaeaea;
}

code {
  font-family: "JetBrains Mono", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "JetBrains Mono", sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
h6 {
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}