@import "../grid";

.home {
  h2 {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .turing-banner {
    min-height: 50em;
    position: relative;
    color: white;

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      text-align: center;
    }

    .title {
      display: inline-block;
      color: white;
      font-family: 'JetBrains Mono', monospace;
      font-weight: bold;
      text-transform: uppercase;
      margin: 2em auto 1em;
      background: black;
      font-size: 2em;

      @media screen and (min-width: 50em) {
        background: black;
        font-size: 3em;
        padding: 0.25em 0.5em;
      }
    }


    .charity {
      display: inline-block;
      text-align: center;
      background-color: #f8dd09;
      color: #3d2459;
      padding: 1em;
      margin: 20em auto 2em;
      text-decoration: none;

      .text {
        margin-bottom: 0.5em;
      }

      svg {
        * {
          fill: #3d2459;
        }

        width: 4em;
        height: auto;
      }
    }

    .buy-now {
      .btn-white {
        margin: 2em auto;
      }
    }
  }

  .featured-collections {
    @include grid-container;
    margin: $spacer/2 auto;

    .collection {
      @include cols(12);
      @include cols(6, $container-breakpoint-md);
      @include pl-0();
      @include pr-0();
      @include pl-auto($container-breakpoint-md);
      @include pr-auto($container-breakpoint-md);
      @include grid-container;

      position: relative;
      min-height: 20em;
      margin-top: 1em;
      background: linear-gradient(to top left, #D30055, #550053);
      background-clip: content-box;
      color: #fff;
      text-decoration: none;

      .bg-image {
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        filter: brightness(70%);
      }

      h3 {
        z-index: 1;
        padding: 0 $spacer;
        text-transform: uppercase;
        font-size: 2em;
        margin: 3em 0 1.5em;
      }

      .btn-container {
        z-index: 1;
        padding: 0 $spacer;
        margin-bottom: 6em;
      }

      .charity {
        z-index: 1;
        width: 4em;
        height: auto;
        position: absolute;
        bottom: $spacer;
        right: $spacer;
      }
    }
  }
}