@import "../../grid";
.menu {
  width: 100%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  color: #fff;
  position: fixed;
  top: 0;
  height: 100%;
  padding: $spacer;
  font-size: 1.5em;
  box-shadow: -1em 0 1.5em rgba(0, 0, 0, 0.58);
  z-index: 10000;

  @media screen and (min-width: $container-breakpoint-md) {
    padding: $spacer * 4;
    width: auto;
  }

  &.expanded {
    animation-name: slide-in;
    animation-duration: 1s;
  }
  &.collapsed {
    animation-name: slide-out;
    animation-duration: 1s;
    left: -150%;
  }

  h2 {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 0.1em;
  }

  nav ul {
    margin-left: $spacer;
    li {
      padding: $spacer/2 0;

      a {
        color: inherit;
        text-decoration: none;

        &:focus {
          background-color: yellow;
          color: black;
          font-weight: bold;
          outline: none;
        }
      }
    }
  }

  button {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fff;
    cursor: pointer;
    padding: $spacer/2;
    font-size: 1em;
    margin: 1em auto;
    outline: none;

    svg {
      margin-right: $spacer/4;
    }

    &:focus {
      color: yellow;
      background-color: transparent;
      font-weight: bold;
      outline: 5px solid #fff;
    }
  }
}

@keyframes slide-in {
  from {
    left: -150%;
  }
  to {
    left: 0;
  }
}
@keyframes slide-out {
  from {
    left: 0;
  }
  to {
    left: -150%;
  }
}